import React from 'react';
// import Modal from '../utils/Modal';

function HeroHome() {

  // const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative">

      {/* Illustration behind hero content */}
      {/* <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div> */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Make yourself <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">optional</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Make <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span> for it.</h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Make your website <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">wonderful</span></h1> */}

            <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">timeline</span></h1>
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Take your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Use your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">calendar</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Make <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Make <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span> for it</h1> */}
            
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">It's about <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Be <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">present</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Supercharge your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">calendar</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">A better <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">calendar</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Love your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">calendar</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">The world's best <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">calendar</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Take control of your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Save <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Your map of <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span></h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Your personal <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">time</span> machine</h1> */}
            {/* <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">Remember <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">everything</span></h1> */}

            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Hands-free planning and tracking.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Turn your calendar into a superpower.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Turn your calendar into a superpower.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Never forget anything.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Automatic planning and tracking so you never forget anything.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Get a glance of your day with no effort.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Effortless planning and tracking.</p> */}

            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Every minute of your life. In one place. Automatically.</p>
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Make your calendar 10x more powerful in just 5 minutes.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Turn your calendar into your favorite tool in 5 minutes.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Hands-free planning and tracking so you never forget anything.</p> */}
            {/* <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Review the past. Preview the future. Be present.</p>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Review the past. Preview the future. Live now.</p>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">100% automatic 24/7 time tracking.</p>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">All your events in one place.</p>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Turn your calendar into your life's timeline.</p>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Your time is precious. Don't waste it.</p>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Frictionless calendar augmentation for the 21st century.</p>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">Effortless calendar augmentation for the 21st century.</p> */}


            {/* <div className="max-w-3xl mx-auto">
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                <div>
                  <a className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="#newsletter">Get early access</a>
                  <a className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="#0">Start free trial</a>
                </div>
                <div>
                  <a className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="#0">Learn more</a>
                </div>
              </div>
            </div> */}
          </div>

          {/* Hero image */}
          {/* <div>
            <div className="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
              <div className="flex flex-col justify-center">
                <img className="mx-auto" src={require('../images/hero-image.png').default} width="768" height="432" alt="Hero" />
                <svg className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="768" height="432" viewBox="0 0 768 432" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hero-ill-a">
                      <stop stopColor="#FFF" offset="0%" />
                      <stop stopColor="#EAEAEA" offset="77.402%" />
                      <stop stopColor="#DFDFDF" offset="100%" />
                    </linearGradient>
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="99.24%" id="hero-ill-b">
                      <stop stopColor="#FFF" offset="0%" />
                      <stop stopColor="#EAEAEA" offset="48.57%" />
                      <stop stopColor="#DFDFDF" stopOpacity="0" offset="100%" />
                    </linearGradient>
                    <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="hero-ill-e">
                      <stop stopColor="#4FD1C5" offset="0%" />
                      <stop stopColor="#81E6D9" offset="25.871%" />
                      <stop stopColor="#338CF5" offset="100%" />
                    </radialGradient>
                    <circle id="hero-ill-d" cx="384" cy="216" r="64" />
                  </defs>
                  <g fill="none" fillRule="evenodd">
                    <circle fillOpacity=".04" fill="url(#hero-ill-a)" cx="384" cy="216" r="128" />
                    <circle fillOpacity=".16" fill="url(#hero-ill-b)" cx="384" cy="216" r="96" />
                    <g fillRule="nonzero">
                      <use fill="#000" xlinkHref="#hero-ill-d" />
                      <use fill="url(#hero-ill-e)" xlinkHref="#hero-ill-d" />
                    </g>
                  </g>
                </svg>
              </div> */}
          {/* <button className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }} aria-controls="modal">
                <svg className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 flex-shrink-0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                  <path d="M10 17l6-5-6-5z" />
                </svg>
                <span className="ml-3">Watch the full video (2 min)</span>
              </button> */}
          {/* </div> */}

          {/* Modal */}
          {/* <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
              <div className="relative pb-9/16">
                <iframe className="absolute w-full h-full" src="https://player.vimeo.com/video/174002812" title="Video" allowFullScreen></iframe>
              </div>
            </Modal> */}

          {/* </div> */}


          <div className="mx-auto">
            <img className="mx-auto" width="406" height="1018" src={require('../images/chronomize5.webp').default} alt="Chronomize screenshot" />
          </div>
          {/* 
          <div class="grid grid-flow-col gap-3">
            <div class="bg-blue-100 col-span-3">
              <img width="500" src={require('../images/chronomize5.png').default} />

            </div>
            <div class="bg-red-100 col-span-3">
              <ol>
                <li>Birthdays: Google Calendar</li>
                <li>Holidays: Google Calendar</li>
                <li>Events: Chronomize Catalog</li>
                <li>Location: Life360</li>
                <li>Sleep: Apple Health</li>
                <li>Fitness: Apple Health</li>
                <li>Commute: Google Maps</li>
                <li>Music: Last.fm</li>
                <li>Movies: Trakt.tv</li>
                <li>Device: RescueTime</li>
                <li>Commits: GitHub</li>
                <li>Transactions: Plaid</li>
                <li>Calls: VoIP.ms</li>
              </ol>
            </div>
          </div> */}

        </div>

      </div>
    </section>
  );
}

export default HeroHome;