import React from 'react';

function FeaturesBlocks() {
  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      {/* <div className="absolute inset-0 top-1/4 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div> */}
      {/* <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            {/* <h2 className="h2 mb-4">Record the past</h2> */}
            <h2 className="h2 mb-4">Review the past</h2>
            <p className="text-xl text-gray-600">Who said a calendar is only about the future? Chronomize connects to all your favorite services to automatically records how you spend your time.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-brown-100" width="64" height="64" rx="32" />
        <g strokeWidth="2">
          <path className="stroke-current text-white text-opacity-50" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
          <path className="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
          <path className="stroke-current text-white text-opacity-50" strokeLinecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
          <path className="stroke-current text-white" strokeLinecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-gray-900 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">nightlight</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Sleep</h4>
              <p className="text-gray-600 text-center">Apple Health, Google Fit</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-yellow-500" width="64" height="64" rx="32" />
        <g strokeWidth="2" transform="translate(19.429 20.571)">
          <circle className="stroke-current text-white" strokeLinecap="square" cx="12.571" cy="12.571" r="1.143" />
          <path className="stroke-current text-white" d="M19.153 23.267c3.59-2.213 5.99-6.169 5.99-10.696C25.143 5.63 19.514 0 12.57 0 5.63 0 0 5.629 0 12.571c0 4.527 2.4 8.483 5.99 10.696" />
          <path className="stroke-current text-white text-opacity-50" d="M16.161 18.406a6.848 6.848 0 003.268-5.835 6.857 6.857 0 00-6.858-6.857 6.857 6.857 0 00-6.857 6.857 6.848 6.848 0 003.268 5.835" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-yellow-500 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">fitness_center</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Fitness</h4>
              <p className="text-gray-600 text-center">Apple Health, Google Fit</p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-gray-300" width="64" height="64" rx="32" />
        <g strokeLinecap="square" strokeWidth="2">
          <path className="stroke-current text-white" d="M20.571 20.571h13.714v17.143H20.571z" />
          <path className="stroke-current text-white text-opacity-50" d="M38.858 26.993l6.397 1.73-4.473 16.549-13.24-3.58" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-gray-300 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">place</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Location</h4>
              <p className="text-gray-600 text-center">Swarm, Life360</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-red-400" width="64" height="64" rx="32" />
        <g strokeLinecap="square" strokeWidth="2">
          <path className="stroke-current text-white text-opacity-50" d="M38.826 22.504a9.128 9.128 0 00-13.291-.398M35.403 25.546a4.543 4.543 0 00-6.635-.207" />
          <path className="stroke-current text-white" d="M19.429 25.143A6.857 6.857 0 0126.286 32v1.189L28 37.143l-1.714.571V40A2.286 2.286 0 0124 42.286h-2.286v2.285M44.571 25.143A6.857 6.857 0 0037.714 32v1.189L36 37.143l1.714.571V40A2.286 2.286 0 0040 42.286h2.286v2.285" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-red-400 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">headphones</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Music</h4>
              <p className="text-gray-600 text-center">Spotify, Last.fm, Libre.fm</p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-blue-600" width="64" height="64" rx="32" />
        <g transform="translate(22.857 19.429)" strokeWidth="2">
          <path className="stroke-current text-white" strokeLinecap="square" d="M12.571 4.571V0H0v25.143h12.571V20.57" />
          <path className="stroke-current text-white" d="M16 12.571h8" />
          <path className="stroke-current text-white" strokeLinecap="square" d="M19.429 8L24 12.571l-4.571 4.572" />
          <circle className="stroke-current text-blue-300" strokeLinecap="square" cx="12.571" cy="12.571" r="3.429" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-red-500 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">movie</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Movies</h4>
              <p className="text-gray-600 text-center">Netflix, Trakt.tv</p>
            </div>


            {/* 6th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-gray-500" width="64" height="64" rx="32" />
        <g strokeWidth="2">
          <path className="stroke-current text-white" d="M32 37.714A5.714 5.714 0 0037.714 32a5.714 5.714 0 005.715 5.714" />
          <path className="stroke-current text-white" d="M32 37.714a5.714 5.714 0 015.714 5.715 5.714 5.714 0 015.715-5.715M20.571 26.286a5.714 5.714 0 005.715-5.715A5.714 5.714 0 0032 26.286" />
          <path className="stroke-current text-white" d="M20.571 26.286A5.714 5.714 0 0126.286 32 5.714 5.714 0 0132 26.286" />
          <path className="stroke-current text-white text-opacity-50" d="M21.714 40h4.572M24 37.714v4.572M37.714 24h4.572M40 21.714v4.572" strokeLinecap="square" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-gray-500 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">devices</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Computer Usage</h4>
              <p className="text-gray-600 text-center">RescueTime, Toggl</p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-purple-400" width="64" height="64" rx="32" />
        <g transform="translate(22.857 19.429)" strokeWidth="2">
          <path className="stroke-current text-white" strokeLinecap="square" d="M12.571 4.571V0H0v25.143h12.571V20.57" />
          <path className="stroke-current text-white" d="M16 12.571h8" />
          <path className="stroke-current text-white" strokeLinecap="square" d="M19.429 8L24 12.571l-4.571 4.572" />
          <circle className="stroke-current text-white text-opacity-50" strokeLinecap="square" cx="12.571" cy="12.571" r="3.429" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-purple-400 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">call</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Phone Calls</h4>
              <p className="text-gray-600 text-center">VoIP.ms</p>
            </div>


            {/* 6th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-green-400" width="64" height="64" rx="32" />
        <g strokeWidth="2">
          <path className="stroke-current text-white" d="M32 37.714A5.714 5.714 0 0037.714 32a5.714 5.714 0 005.715 5.714" />
          <path className="stroke-current text-white" d="M32 37.714a5.714 5.714 0 015.714 5.715 5.714 5.714 0 015.715-5.715M20.571 26.286a5.714 5.714 0 005.715-5.715A5.714 5.714 0 0032 26.286" />
          <path className="stroke-current text-white" d="M20.571 26.286A5.714 5.714 0 0126.286 32 5.714 5.714 0 0132 26.286" />
          <path className="stroke-current text-white text-opacity-50" d="M21.714 40h4.572M24 37.714v4.572M37.714 24h4.572M40 21.714v4.572" strokeLinecap="square" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-green-400 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">attach_money</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Transactions</h4>
              <p className="text-gray-600 text-center">Plaid</p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect className="fill-current text-gray-800" width="64" height="64" rx="32" />
        <g strokeLinecap="square" strokeWidth="2">
          <path className="stroke-current text-white" d="M20.571 20.571h13.714v17.143H20.571z" />
          <path className="stroke-current text-white text-opacity-50" d="M38.858 26.993l6.397 1.73-4.473 16.549-13.24-3.58" />
        </g>
      </g>
    </svg> */}
              <div className="rounded-full bg-gray-800 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">code</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Commits</h4>
              <p className="text-gray-600 text-center">GitHub</p>
            </div>

          </div>
        </div>

        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            {/* <h2 className="h2 mb-4">Plan the future</h2> */}
            <h2 className="h2 mb-4">Preview the future</h2>
            <p className="text-xl text-gray-600">If it's not on your calendar, it doesn't exist. Chronomize helps you plan the future so you always know what's up next.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">

              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-blue-500" width="64" height="64" rx="32" />
                  <g strokeWidth="2">
                    <path className="stroke-current text-white text-opacity-50" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
                    <path className="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
                    <path className="stroke-current text-white text-opacity-50" strokeLinecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
                    <path className="stroke-current text-white" strokeLinecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
                  </g>
                </g>
              </svg> */}
              <div className="rounded-full bg-blue-500 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white">create</span>
              </div>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Manual Entry</h4>
              <p className="text-gray-600 text-center">Use the calendar you already know and love to manually create events.</p>
            </div>
            {/* 
            <span class="material-icons-outlined">code</span>
            <span class="material-icons-outlined">park</span>
            <span class="material-icons-outlined">celebration</span>
            <span class="material-icons-outlined">cake</span>
            <span class="material-icons-outlined">touch_app</span>
            <span class="material-icons-outlined">music</span>
            <span class="material-icons-outlined">movie</span>
            <span class="material-icons-outlined">money</span>
            <span class="material-icons-outlined">cash</span>
            <span class="material-icons-outlined">map</span>
            <span class="material-icons-outlined">book</span>
            <span class="material-icons-outlined">pin</span>
            <span class="material-icons-outlined">directions</span>
            <span class="material-icons-outlined">laptop</span>
            <span class="material-icons-outlined">devices</span>
            <span class="material-icons-outlined">nightlight</span>
            <span class="material-icons-outlined">fitness_center</span>
            <span class="material-icons-outlined">directions_run</span>
            <span class="material-icons-outlined">menu_book</span>
            <span class="material-icons-outlined">call</span>
            <span class="material-icons-outlined">attach_money</span>
            <span class="material-icons-outlined">credit_card</span>
            <span class="material-icons-outlined">directions</span>
            <span class="material-icons-outlined">directions_car</span>
            <span class="material-icons-outlined">directions_bus</span>
            <span class="material-icons-outlined">multiple_stops</span> */}

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-green-400" width="64" height="64" rx="32" />
                  <g strokeWidth="2">
                    <path className="stroke-current text-white text-opacity-50" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
                    <path className="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
                    <path className="stroke-current text-white text-opacity-50" strokeLinecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
                    <path className="stroke-current text-white" strokeLinecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
                  </g>
                </g>
              </svg> */}
              <div className="rounded-full bg-green-400 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">cake</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Birthdays</h4>
              <p className="text-gray-600 text-center">Never miss a birthday. See birthdays from all your contacts.</p>
            </div>

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-green-600" width="64" height="64" rx="32" />
                  <g strokeWidth="2">
                    <path className="stroke-current text-white text-opacity-50" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
                    <path className="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
                    <path className="stroke-current text-white text-opacity-50" strokeLinecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
                    <path className="stroke-current text-white" strokeLinecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
                  </g>
                </g>
              </svg> */}
              <div className="rounded-full bg-green-600 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">celebration</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Holidays</h4>
              <p className="text-gray-600 text-center">Celebrate often. See national, international, and fictional holidays.</p>
            </div>

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-gray-500" width="64" height="64" rx="32" />
                  <g strokeWidth="2">
                    <path className="stroke-current text-white text-opacity-50" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
                    <path className="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
                    <path className="stroke-current text-white text-opacity-50" strokeLinecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
                    <path className="stroke-current text-white" strokeLinecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
                  </g>
                </g>
              </svg> */}
              <div className="rounded-full bg-gray-500 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">directions</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Travel Time</h4>
              <p className="text-gray-600 text-center">Travel time is automatically added to events based on your location.</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-blue-600" width="64" height="64" rx="32" />
                  <g strokeLinecap="square" strokeWidth="2">
                    <path className="stroke-current text-blue-300" d="M38.826 22.504a9.128 9.128 0 00-13.291-.398M35.403 25.546a4.543 4.543 0 00-6.635-.207" />
                    <path className="stroke-current text-white" d="M19.429 25.143A6.857 6.857 0 0126.286 32v1.189L28 37.143l-1.714.571V40A2.286 2.286 0 0124 42.286h-2.286v2.285M44.571 25.143A6.857 6.857 0 0037.714 32v1.189L36 37.143l1.714.571V40A2.286 2.286 0 0040 42.286h2.286v2.285" />
                  </g>
                </g>
              </svg> */}
              <div className="rounded-full bg-yellow-400 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">touch_app</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Smart Capture</h4>
              <p className="text-gray-600 text-center">Browser extension lets you add any event from any website with one click.</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-blue-900" width="64" height="64" rx="32" />
                  <g strokeWidth="2" transform="translate(19.429 20.571)">
                    <circle className="stroke-current text-white" strokeLinecap="square" cx="12.571" cy="12.571" r="1.143" />
                    <path className="stroke-current text-white" d="M19.153 23.267c3.59-2.213 5.99-6.169 5.99-10.696C25.143 5.63 19.514 0 12.57 0 5.63 0 0 5.629 0 12.571c0 4.527 2.4 8.483 5.99 10.696" />
                    <path className="stroke-current text-white text-opacity-50" d="M16.161 18.406a6.848 6.848 0 003.268-5.835 6.857 6.857 0 00-6.858-6.857 6.857 6.857 0 00-6.857 6.857 6.848 6.848 0 003.268 5.835" />
                  </g>
                </g>
              </svg> */}
              <div className="rounded-full bg-blue-900 h-14 w-14 flex items-center justify-center p-1 -mt-1 mb-2">
                <span class="material-icons-outlined text-white text-2xl">auto_stories</span>
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Event Catalog</h4>
              <p className="text-gray-600 text-center">Subcribe to thousands of curated public events and calendars.</p>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
