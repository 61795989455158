import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="flex block space-x-4" aria-label="Cruip">
              {/* <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="header-logo">
                    <stop stopColor="#4FD1C5" offset="0%" />
                    <stop stopColor="#81E6D9" offset="25.871%" />
                    <stop stopColor="#338CF5" offset="100%" />
                  </radialGradient>
                </defs>
                <rect width="32" height="32" rx="16" fill="url(#header-logo)" fillRule="nonzero" />
              </svg> */}
              <svg id="Raw" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" style={{
                    width: 30,
                    height: 36,
                    marginRight: 2,
            }}>
                <defs><radialGradient id="header-logo" r="79.941%" cy="86.063%" cx="21.152%" fy="86.063%" fx="21.152%"><stop offset="0%" stop-color="#4FD1C5"></stop><stop offset="" stop-color="#4FD1C5"></stop><stop stop-color="#338CF5" offset="100%"></stop></radialGradient></defs>
                <rect width="256" height="256" fill="none"></rect>
                <path d="M59.31371,40H196.68629a8,8,0,0,1,5.65686,13.65685L53.65685,202.34315A8,8,0,0,0,59.31371,216H196.68629a8,8,0,0,0,5.65686-13.65685L53.65685,53.65685A8,8,0,0,1,59.31371,40Z" fill="none" stroke="url(#header-logo)" stroke-linecap="square" stroke-linejoin="square" stroke-width="30"></path>
              </svg>
              <h1 className="font-bold text-2xl bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">Chronomize</h1>

            </Link>
          </div>

          {/* Site navigation */}
          < nav className="flex flex-grow" >
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {/* <li><a class="lo lg ux mj uy nh nb hb hj hk" href="pricing.html" >Pricing</a></li>
              <li><a class="lo lg ux mj uy nh nb hb hj hk" href="about.html" >About us</a></li>
              <li><a class="lo lg ux mj uy nh nb hb hj hk" href="tutorials.html">Tutorials</a></li>
              <li><a class="lo lg ux mj uy nh nb hb hj hk" href="blog.html">Blog</a></li> */}
              {/* <li>
                <Link to="/signin" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Sign in</Link>
              </li> */}
              {/* <li>
                <Link to="/signup" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span>Sign up</span>
                  <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>
                </Link>
              </li>        */}
              <li>
                <a href="#newsletter" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span>Join</span>
                  {/* <svg className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg> */}
                </a>
              </li>
            </ul>

          </nav >

        </div >
      </div >
    </header >
  );
}

export default Header;
